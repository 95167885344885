'use client'
import Link from "next/link";
import { useState } from "react";

const CloseButton = ({ closeSidebar }) => (
    <button onClick={closeSidebar} className="p-4" aria-label="Fermer le menu">
        <svg viewBox="0 0 24 24" width="24" height="24" stroke="white" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round">
            <line x1="18" y1="6" x2="6" y2="18"></line>
            <line x1="6" y1="6" x2="18" y2="18"></line>
        </svg>
    </button>
)

const LinkItem = ({ href, children, closeSidebar }) => (
    <Link href={href} className="p-2 text-gray-200 transition-opacity bg-custom-blue hover:bg-custom-yellow hover:text-black rounded duration-200 text-xl w-full" onClick={closeSidebar}>
        {children}
    </Link>
)


export const Sidebar = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleSidebar = () => setIsOpen(!isOpen);

    return (
        <>
            {/* Backdrop */}
            <div className={`fixed inset-0 bg-black bg-opacity-50 z-30 transition-opacity duration-300 ease-in-out ${isOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'}`} onClick={toggleSidebar}></div>

            {/* Sidebar */}
            <div className={`fixed top-0 right-0 h-full bg-custom-blue shadow-md z-40 transition-transform duration-500 ease-in-out ${isOpen ? 'translate-x-0' : 'translate-x-full'} w-full md:w-[400px] p-4`}>
                {/* Sidebar content */}
                <div className="flex flex-col items-end w-full">
                    <CloseButton closeSidebar={() => setIsOpen(false)} />
                    <nav className="flex flex-col p-4 gap-4 w-full">
                        <LinkItem closeSidebar={() => setIsOpen(false)} href="/">🏠 Accueil</LinkItem>
                        <LinkItem closeSidebar={() => setIsOpen(false)} href="/shop">🛍️ Boutique</LinkItem>
                        <LinkItem closeSidebar={() => setIsOpen(false)} href="/le-club">🏛️ Le Club</LinkItem>
                        <LinkItem closeSidebar={() => setIsOpen(false)} href="/actualites">🗞️ Actualités</LinkItem>
                        <LinkItem closeSidebar={() => setIsOpen(false)} href="/breves">📃 Brèves</LinkItem>
                        <LinkItem closeSidebar={() => setIsOpen(false)} href="/sponsors">💸 Sponsors</LinkItem>
                        <LinkItem closeSidebar={() => setIsOpen(false)} href="/feminines/matchs">
                            <div className="w-full">
                                🤾‍♀️ Féminines - Matchs
                            </div>
                        </LinkItem>
                        <LinkItem closeSidebar={() => setIsOpen(false)} href="/loisir/matchs">
                            <div className="w-full">
                                🤾🏼‍♂️ Loisir - Matchs
                            </div>
                        </LinkItem>
                    </nav>
                </div>
            </div>

            {/* Open Sidebar Button */}
            <button onClick={toggleSidebar} className="z-10 p-4" aria-label="Ouvrir le menu" >
                <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round">
                    <line x1="3" y1="12" x2="21" y2="12"></line>
                    <line x1="3" y1="6" x2="21" y2="6"></line>
                    <line x1="3" y1="18" x2="21" y2="18"></line>
                </svg>
            </button>
        </>
    );
}
