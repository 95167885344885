"use client";
import { usePathname } from "next/navigation";
import { teamFeminines, teamLoisir } from "./teamsPathname";

export const useIsFeminines = () => {
    const pathname = usePathname();
    const [_, _team, ...rest] = pathname.split('/')
    const isHomepage = pathname === "/";
    const isFeminines = pathname.includes(teamFeminines) || isHomepage;
    const isOutsideTeam = !pathname.includes(teamFeminines) && !pathname.includes(teamLoisir);
    return { isFeminines, rest, isOutsideTeam, pathname, isHomepage }
}

