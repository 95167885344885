'use client'
import { useIsFeminines } from "@utils/useSearchParameters"
import { teamFeminines, teamLoisir } from "@utils/teamsPathname";
import Link from "next/link"

export const HomeButton = () => {
    const { isFeminines, isOutsideTeam } = useIsFeminines();
    return (
        <Link href={isOutsideTeam ? teamFeminines : isFeminines ? teamFeminines : teamLoisir} className="custom-text-gradient text-xl tracking-wide">
            <strong> PBHB </strong>
        </Link>
    )
}
