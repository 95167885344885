'use client'
import { useIsFeminines } from '@utils/useSearchParameters';
import { teamFeminines, teamLoisir } from "@utils/teamsPathname";
import { useRouter } from 'next/navigation';


export const TeamSwitch = () => {
    const router = useRouter();
    const { isFeminines, pathname, isHomepage } = useIsFeminines();
    const updateQueryParameter = () => {
        if (isHomepage) {
            router.push(teamLoisir);
            return
        };

        const newPathname = isFeminines ? pathname.replace(teamFeminines, teamLoisir) : pathname.replace(teamLoisir, teamFeminines);

        router.push(newPathname);
    };

    return (
        <div className="flex items-center justify-center w-full">
            <label className="flex items-center cursor-pointer">
                <div className="relative">
                    <input type="checkbox" className="sr-only" checked={isFeminines} onChange={updateQueryParameter} />
                    <div className={`block w-14 h-8 rounded-full transition ${isFeminines ? 'bg-custom-yellow' : 'bg-custom-blue'}`}></div>
                    <div className={`dot absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition transform ${isFeminines ? 'translate-x-0' : 'translate-x-full'}`}></div>
                </div>
                <div className="ml-3 text-gray-700 font-medium w-[100px] text-3xl">
                    {isFeminines ? '🤾‍♀️' : '🤾‍♀️🤾🏼‍♂️'}
                </div>
            </label>
        </div>
    );
};

