import(/* webpackMode: "eager", webpackExports: ["HomeButton"] */ "/vercel/path0/components/homeButton.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Sidebar"] */ "/vercel/path0/components/sidebar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TeamSwitch"] */ "/vercel/path0/components/teamSwitch.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/images/3B.png");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/images/Intermarche.webp");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/images/LaSoupeAuxChoux.png");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/images/Tagperform.webp");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.1_react-dom@18.2.0_react@18.2.0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/.pnpm/next@14.2.1_react-dom@18.2.0_react@18.2.0/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.1_react-dom@18.2.0_react@18.2.0/node_modules/next/font/google/target.css?{\"path\":\"components/sharedLayout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
